import { render, staticRenderFns } from "./ShopCommodityCategoryTable.vue?vue&type=template&id=6ba6a484&scoped=true&"
import script from "./ShopCommodityCategoryTable.vue?vue&type=script&lang=js&"
export * from "./ShopCommodityCategoryTable.vue?vue&type=script&lang=js&"
import style0 from "./ShopCommodityCategoryTable.vue?vue&type=style&index=0&id=6ba6a484&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba6a484",
  null
  
)

export default component.exports